/*
Theme Name: ishiharatatami
*/

@import-normalize;

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: #000;
}

html {
  font-size: 62.5%;
}


body {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  background-color: #fffefa;
  color: #333;
  font: 1.6rem;
  margin: 0;
}

/* wrapper
 ------------------------*/
#wrapper {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  #wrapper {
    width: 1024px;
    margin: 0 auto;
  }
}

/* header
 ------------------------*/
header {
  width: 100%;
  margin: 10px 0;

  div.logo {
    width: 182px;
    height: 39px;
    margin: 0 0 0 20px;
  }
}

/* Humburger Navigation */
@media only screen and (max-width: 780px) {
  #wrapper {
    &.active {

      nav {

        transform: translate(0, 0);
        opacity: 1;

        .global-navi {
        }
        .menu-icon {
          //@include create-transform($trans-x: 250px);
        }
      }

      .headerinner {
        transform: translateX(-250px);
        opacity: 0.6;
      }
    }
  }

  /* nav */
  nav {
    color: #333;
    position: fixed;
    top: 0;
    width: 250px;
    height: 100%;
    right: 0;
    z-index: 9;
    transform: translate(250px, 0);
    opacity: 0;
    transition: transform .6s ease-in , opacity .6s ease-in;
    //background: rgba(195,233,200,0.3);

    .global-navi {
      position: absolute;
      width: 250px;
      height: 100%;
      top: 0;
      //background: rgba(195,233,200,0.3);

      ul {
        width: 250px;
        height: 100%;
        margin: 0 0 0 1em;
        padding: 0;
        list-style-type: none;
        background: white;

        li {
          width: 240px;
          height: 4.0rem;
          padding: 0 0 0 10px;
          border-bottom: 1px solid white;
          line-height: 4.0rem;
          font-size: 1.6rem;

          a {
            display: block;
            text-decoration: none;
          }

        }
      }
    }
  }

  .menu-icon {
    position: fixed;
    display: block;
    padding: 9px 8px 5px;
    margin: 10px 10px 0 0;
    background: #fff;
    cursor: pointer;
    border: 1px solid #666;
    border-radius: 0.5em;
    top: 0;
    right: 0;
    transition: transform .6s ease-in;

    span {
      display: block;
      width: 22px;
      height: 2px;
      background: #666;
      margin-bottom: 5px;
      transition: transform .6s ease-in;
    }

    &.active {
      transform: translate(-250px, 0);
      padding: 6px 8px 10px;

      &:hover {

        span {
          //margin-bottom: 2px;
        }
      }

      .bar1 {
        //transform: rotate(45deg) translateX(5px) translateY(5px);
        transform: rotate(45deg) translateX(8px) translateY(8px);
      }

      .bar2 {
        opacity: 0;
      }

      .bar3 {
        transform: rotate(-45deg) translateX(1px) translateY(-1px);
        //margin-bottom: 2px;
      }

    }

  }

  .menu-icon-container {
    position: absolute;
    right: 3px;
    z-index: 10;
  }

  .headerinner {
    transition: transform .6s, opacity 1s;

    p {
      font-size: 1.4rem;
      line-height: 2rem;
      margin: 10px;
    }
  }
}

@media only screen and (min-width: 781px) {
  .global-navi {
    width: 100%;
    ul {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      padding: 0;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      li {
        display: block;
        flex-basis: 20%;
        font-size: 1.6rem;
        line-height: 2;
        margin: 0;
        text-align: center;
        //background-color: aquamarine;
        //border-left: 2px solid #fff;
        //border-right: 2px solid #fff;
      }
    }
  }
}

/* container
 ------------------------*/
#container {
  width: 100%;
}

/* main-img
 ------------------------*/
.main-img {
  width: 100%;
  img {
    width: 100%;
  }
}

/* contents
 ------------------------*/
.contents {
  width: 100%;
}

p.greeting {
  display: flex;
  justify-content: center;
  font-size: 1.6em;
  line-height: 1.4;
  padding: 0 10px;
  text-align: left;
}

.contents h2.top-title {
  font-size: 3em;
  font-family: "正楷書CB1", "Sei Kaisho CB1";
  margin: 20px 0 10px;
  text-align: center;
  color: #009245;
}

/* top-tatami
 ------------------------*/

.top-tatami {
  background: #fff url("../img/tatami-bg.gif");
  padding: 10px 0;
  margin: 0 0 10px;
  h3 {
    width: 100%;
    font-size: 2em;
    line-height: 1.2;
    text-align: center;
    margin: 0 0 10px;
  }
}

div.otokomae-nadeshiko {
  margin: 0 0 20px;
}

div.otokomae-nadeshiko-img {
  img {
    display: block;
    float: left;
    width: 50%;
  }
}

@media only screen and (min-width: 600px) {
  div.otokomae-nadeshiko-img {
    width: 600px;
    margin: 0 auto;
  }
}

.for-detail {
  //width: 100%;
  //text-align: center;

  p.detail {
    width: 200px;
    font-size: 2em;
    line-height: 2;
    margin: 10px auto;
    text-align: center;
    color: #fff;
    border: 2px solid #fff;
    background: #009245;
  }
}

div.heriari-tatami,
div.herinashi-tatami {
  width: 100%;
  margin: 0 auto 20px;
  div.heriari-tatami-img,
  div.herinashi-tatami-img {
    img {
      display: block;
      width: 70%;
      margin: 0 auto;
    }
  }
}

@media only screen and (min-width: 700px) {
  div.heriarinashi {
    width: 100%;
    display: flex;
    justify-content: center;

    div.heriari-tatami,
    div.herinashi-tatami {
      width: 400px;
      margin: 0 auto;
      float: left;
    }
  }
}

/* top-service
 ------------------------*/
h2.service-title {
  width: 100%;
  font-size: 3em;
  line-height: 1.2;
  text-align: center;
  margin: 0 0 10px;
  color: #009245;
}

.top-service {
  width: 100%;
  img {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
}

p.service-item {
  width: 100%;
  font-size: 1.8em;
  line-height: 1.2;
  text-align: center;
  margin: 0 0 10px;
  text-decoration-line: underline;
  text-decoration-color: #009245;
  //border-bottom: #009245 solid 2px;
}

p.service-text {
  width: 80%;
  font-size: 1.4em;
  line-height: 1.4;
  text-align: center;
  margin: 10px auto 20px;
}

@media only screen and (min-width: 700px) {
  .top-service {
    display: flex;
    flex-wrap: wrap;
  }

  .top-service01,
  .top-service02,
  .top-service03,
  .top-service04 {
    width: 50%;
    float: left;
  }
}

/* top-contact
 ------------------------*/
.contact {
  width: 80%;
  margin: 10px auto;
  padding: 5px 0 0;
  border-top: 2px solid #999;
  border-bottom: 2px solid #999;
}

p.address {
   width: 100%;
   font-size: 1.6em;
   line-height: 1.6;
   text-align: center;
   margin: 0 0 5px;
 }

.tel {
  width: 100%;
  font-size: 1.8em;
  line-height: 1.6;
  text-align: center;
  margin: 0 0 5px;
  &:before {
    content: '';
    background-image: url("../img/tel.png");
    background-size: contain;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: text-top;
  }
}

.contact-link {
  //width: 100%;
  //text-align: center;

  p.contact-linkicon {
    width: 200px;
    font-size: 1.6em;
    line-height: 2;
    margin: 10px auto;
    text-align: center;
    color: #fff;
    border: 2px solid #fff;
    background: #009245;
  }
}

div.googlemap-wrap {
  width: 80%;
}

/* top-area
 ------------------------*/
h3.area {
  width: 100%;
  font-size: 3em;
  line-height: 1.2;
  text-align: center;
  margin: 0 0 10px;
  color: #009245;
}

.area-box {
  width: 80%;
  margin: 0 auto;
  dl {
    width: 100%;
    font-size: 1.6em;
    line-height: 1.6;
  }
}

.area-text {
  width: 100%;
  font-size: 2em;
  line-height: 1.2;
  text-align: center;
  margin: 0 0 10px;
}

@media only screen and (min-width: 700px) {
  dt {
    //float: left;
  }
}

/* footer
 ------------------------*/

footer p.footer-text{
  width: 100%;
  text-align: center;
  font-size: 1.4em;
  line-height: 4;
  margin: 0;
  color: #fff;
  background-color: #009245;
}

/* brand
 ------------------------*/
.brand-topimg {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    width: 50%;
    height: 50%;
    display: flex;
  }
}

.contents h2.brand-title {
  font-size: 3em;
  font-family: "正楷書CB1", "Sei Kaisho CB1";
  margin: 20px 0 10px;
  text-align: center;
  color: #009245;
}

.brand {
  width: 95%;
  margin: 0 auto;
  .otokomae,
  .yamatonadeshiko {
    p.otokomae-txt,
    p.yamatonadeshiko-txt {
      font-size: 1.8em;
      line-height: 1.4;
      margin: 0 0 10px;
      text-align: center;
    }
    img.table-img {
      display: block;
      width: 100%;
      margin: 0 0 10px;
    }
    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style-type: none;
      li {
        width: 46%;
        padding: 0 2% 10px;
        img {
          display: block;
          width: 100%;
        }
        dl {
          background-color: #ebf4ce;
          color: #328034;
          padding: 3%;
          dt {
            font-size: 1.6em;
            line-height: 1.4;
          }
          dd {
            margin: 0;
            font-size: 1.4em;
            line-height: 1.2;
          }
        }

      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .brand {
    .otokomae,
    .yamatonadeshiko {
      ul {
        li {
          width: 31%;
          padding: 0 1% 10px;
        }
      }
    }
  }
}

/* heriari-herinasi
 ------------------------*/
.heriari-merit {
  width: 80%;
  margin: 0 auto 20px;

  h3.merit-title {
    width: 100%;
    font-size: 2em;
    line-height: 1.2;
    text-align: left;
    margin: 0 0 10px;
    padding: 0 0 3px 10px;
    border-left: 10px solid #009245;
    border-bottom: 2px solid #009245;
  }
  p {
    width: 100%;
    font-size: 1.6em;
    line-height: 1.4;
    text-align: left;
    margin: 0 0 10px;
  }
}

.heriari-imgbox {
  img {
    width: 80%;
    margin: 0 auto 10px;
    display: block;
  }
}
@media only screen and (min-width: 700px) {
  div.heriari-imgbox {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 400px;
      padding: 0 10px;
      margin: 0 auto;
      float: left;
    }
  }
}
/* faq
 ------------------------*/
.contents h2.fqa-title {
  font-size: 3em;
  font-family: "正楷書CB1", "Sei Kaisho CB1";
  margin: 20px 0 10px;
  text-align: center;
  color: #009245;
}

.fqa {
  width: 80%;
  margin: 0 auto;
  ul.fqa-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.6em;
    li {
      padding: 10px 0;
      dt {
        padding: 10px 10px 10px 2em;
        background: rgba(255,0,0,0.3);
        line-height: 1.2;
        &::before {
          font-size: 1.4em;
          color: rgba(255,0,0,0.6);
          content:'Q';
          margin: 0.3em 0.3em 0 -1em;
        }
      }

      dd {
        margin: 0;
        padding: 10px 10px 10px 2em;
        line-height: 1.4;
        background: rgba(3,146,69,0.3);
        display: flex;
        &::before {
          font-size: 1.4em;
          color: rgba(3,146,69,0.6);
          content: 'A';
          margin: 0 0.3em 0 -1em;
        }

        p {
          margin: 0;
          padding: 0.2em 0 0;
        }
      }
    }
  }
}

/* blog
 ------------------------*/

.single-tatami_blog {
  article.blog-contents {
    width: 90%;
    margin: 0 auto 20px;
    h1 {
      font-size: 3em;
      font-family: "正楷書CB1", "Sei Kaisho CB1";
      margin: 20px 0 10px;
      text-align: center;
      color: #009245;
    }
    h2 {
      font-size: 2em;
      text-align: left;
      color: #009245;
      border-left: 5px solid #009245;
      padding-left: 5px;
    }
    p {
      font-size: 1.6em;
      text-align: left;
      line-height: 1.4;
    }
    p.breadcrumbs {
      margin: 1em 0 0;
      font-size: 1.2em;
      text-align: left;
      a {
        color: #009245;
        text-decoration: underline;
      }
    }
    p.blog-date {
      margin: 0 0 0.4em;
      font-size: 1.6em;
      text-align: right;
    }
    div.previous-next-link {
      text-align: center;
    }
    p.previous-link {
      font-size: 1.4em;
      text-align: left;
      display: inline-block;
      padding: 0 20px 0 0;
      margin: 0;
    }
    p.next-link {
      font-size: 1.4em;
      text-align: right;
      display:inline-block;
      margin: 0;
    }
  }
}

@media only screen and (min-width: 700px) {
  .single-tatami_blog {
    article.blog-contents {
      width: 80%;
    }
  }
}

/* blog-archive
 ------------------------*/

.post-type-archive-tatami_blog {
  article.blog-archive {
    width: 80%;
    margin: 0 auto;
    h1 {
      font-size: 3em;
      font-family: "正楷書CB1", "Sei Kaisho CB1";
      margin: 20px 0 10px;
      text-align: center;
      color: #009245;
    }
    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: flex;
        flex-flow: column wrap;
      }

      p {
        font-size: 1.8em;
        text-align: left;
        margin: 1em 0 0;

        &.blog-date {
          display: block;
        }
      }

      h2 {
        display: block;
        font-size: 1.8em;
        text-align: left;
        color: #009245;
        margin: 1em 0;
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .post-type-archive-tatami_blog {
    article.blog-archive {
      ul {
        li {
          flex-flow: row nowrap;

          p {
            &.blog-date {
              flex-basis: 20%;

            }
          }

          h2 {
            flex-basis: 80%;
          }
        }
      }
    }
  }
}

/* example
 ------------------------*/

.single-tatami_example {
  article.example-contents {
    width: 90%;
    margin: 0 auto 20px;
    h1 {
      font-size: 2em;
      font-family: "正楷書CB1", "Sei Kaisho CB1";
      margin: 20px 0 10px;
      text-align: center;
      color: #009245;
    }
    h2 {
      font-size: 2em;
      text-align: left;
      color: #009245;
      border-left: 5px solid #009245;
      padding-left: 5px;
    }
    p {
      font-size: 1.6em;
      text-align: left;
    }
    p.breadcrumbs {
      margin: 1em 0 0;
      font-size: 1.2em;
      text-align: left;
      a {
        color: #009245;
        text-decoration: underline;
      }
    }
    p.blog-date {
      margin: 0 0 0.4em;
      font-size: 1.6em;
      text-align: right;
    }
    div.previous-next-link {
      text-align: center;
    }
    p.previous-link {
      font-size: 1.4em;
      text-align: left;
      display: inline-block;
      padding: 0 20px 0 0;
      margin: 0;
    }
    p.next-link {
      font-size: 1.4em;
      text-align: right;
      display:inline-block;
      margin: 0;
    }
  }
}

@media only screen and (min-width: 700px) {
  .single-tatami_example {
    article.example-contents {
      width: 80%;
    }
  }
}

/* example-archive
 ------------------------*/

.post-type-archive-tatami_example {
  article.example-archive {
    width: 80%;
    margin: 0 auto;
    h1 {
      font-size: 3em;
      font-family: "正楷書CB1", "Sei Kaisho CB1";
      margin: 20px 0 10px;
      text-align: center;
      color: #009245;
    }
    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-flow: column wrap;

      li {
        flex-basis: 100%;

        div.example-thumbnail {
          text-align: center;
        }

        h2 {
          display: block;
          font-size: 1.8em;
          text-align: center;
          color: #009245;
          margin: 1em 0;

          a {
            color: #009245;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .post-type-archive-tatami_example {
    article.example-archive {
      ul {
        display: flex;
        flex-flow: row wrap;

        li {
          flex-basis: 50%;

          div.example-thumbnail {
            text-align: center;
          }
          h2 {
            text-align: center;
          }
        }
      }
    }
  }
}

/* pagination
 ------------------------*/

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
  position: relative;
  font-size: 13px;
}

.pagination span, .pagination a {
  display: block;
  width: auto;
  margin: 4px;
  padding: 8px;
  border: 1px solid #000;
  background-color: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 16px;
}

/* ページ番号 */
.pagination .pager{
  width: 32px;
}

/* ホバー時 & 現在のページ */
.pagination a:hover,
.pagination .current  {
  color: #fff;
  border-color: #000;
  background-color: #000;
}

/* 前へ */
.pagination a.prev {
  margin-right: 16px;
}
/* 次へ */
.pagination a.next {
  margin-left: 16px;
}
/* 最初へ */
.pagination a.first {}
/* 最後へ */
.pagination a.last {}

/* Page x / y */
.pagination span.page_num {
  display: none;
}



/* contact
 ------------------------*/
.contents h2.contact-title {
  font-size: 3em;
  font-family: "正楷書CB1", "Sei Kaisho CB1";
  margin: 20px 0 10px;
  text-align: center;
  color: #009245;
}

.contact-form {
  width: 80%;
  margin: 0 auto;
  p {
    font-size: 1.4em;
    text-align: center;
  }
}

/*Contact Form 7カスタマイズ*/
/*スマホContact Form 7カスタマイズ*/
@media only screen and (max-width:500px){
  .inquiry th,.inquiry td {
    display:block!important;
    width:100%!important;
    border-top:none!important;
    -webkit-box-sizing:border-box!important;
    -moz-box-sizing:border-box!important;
    box-sizing:border-box!important;
  }
  .inquiry tr:first-child th{
    border-top:1px solid #d7d7d7!important;
  }
  /* 必須・任意のサイズ調整 */
  .inquiry .haveto,.inquiry .any {
    font-size:10px;
  }}
/*見出し欄*/
.inquiry {
  margin: 0 auto;
  //width: 500px;
}

.inquiry th{
  text-align:left;
  font-size:14px;
  color:#444;
  padding-right:5px;
  width:40%;
  background:#f7f7f7;
  border:solid 1px #d7d7d7;
}
/*通常欄*/
.inquiry td{
  font-size:13px;
  border:solid 1px #d7d7d7;
}
/*横の行とテーブル全体*/
.entry-content .inquiry tr,.entry-content table{
  border:solid 1px #d7d7d7;
}
/*必須の調整*/
.haveto{
  font-size:7px;
  padding:5px;
  background:#ff9393;
  color:#fff;
  border-radius:2px;
  margin-right:5px;
  position:relative;
  bottom:1px;
}
/*任意の調整*/
.any{
  font-size:7px;
  padding:5px;
  background:#93c9ff;
  color:#fff;
  border-radius:2px;
  margin-right:5px;
  position:relative;
  bottom:1px;
}
/*ラジオボタンを縦並び指定*/
.verticallist .wpcf7-list-item{
  display:block;
}
/*送信ボタンのデザイン変更*/
#formbtn{
  display: block;
  padding: 8px;
  //width:350px;
  background:#ffaa56;
  color:#fff;
  font-size:1.4em;
  font-weight:bold;
  border-radius:2px;
  margin:25px auto 0;
}
/*送信ボタンマウスホバー時*/
#formbtn:hover{
  background:#fff;
  color:#ffaa56;
  border:2px solid #ffaa56;
}